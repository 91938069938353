import { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { DataContext } from './DataContext';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { Carousel } from 'primereact/carousel';
import { useNavigate } from 'react-router';
import ProgressiveImage from 'react-progressive-graceful-image'
function HomeMedia({ setActiveTab, isMobile }) {
    const [isVisible, setIsVisible] = useState(false);
    const strategicRef = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { media } = useContext(DataContext)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isVisible) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.4 }
        );
        if (strategicRef.current) observer.observe(strategicRef.current);
        return () => observer.disconnect();
    }, []);
    const mediaFooter = (
        < div className="flex flex-wrap justify-content-end gap-2" >
            <Button label={t("more")} icon="pi pi-camera" className="p-button-outlined p-button-secondary" onClick={() => {
                navigate('/media');
                setActiveTab("Media");
            }} />
        </div >
    )
    const mediaTemplate = (media) => {
        return (
            <div className="media-item m-4 mb-1">
                <ProgressiveImage src={media.image} placeholder={media.imageSmall}>
                    {(src) => <img src={src} alt={media.name} className="media-image"
                        onClick={() => window.open(media.link, '_blank')}
                    />}
                </ProgressiveImage>
                <Button link className="media-name text-primary-700" onClick={() => window.open(media.link, '_blank')} label={media.name} />
                <h4 className="media-date text-primary-400" >{media.date}</h4>
            </div>
        );
    };
    return (
        <motion.div className='flex flex-col align-items-center'
            initial={{ opacity: 0, x: 200 }}
            animate={isVisible ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.1 }} ref={strategicRef}>
            <div className='title-text'>Media</div>
            <Carousel
                value={media}
                circular
                autoplayInterval={3500}
                itemTemplate={mediaTemplate}
                pt={{
                    indicators: {
                        style: {
                            padding: "2px"
                        }
                    },
                    nextButtonIcon: {
                        style: {
                            color: "var(--primary-700)"
                        }
                    },
                    previousButtonIcon: {
                        style: {
                            color: "var(--primary-700)"
                        }
                    }
                }}
                responsiveOptions={[
                    {
                        breakpoint: '2024px',
                        numVisible: 3,
                        numScroll: 2,
                    },
                    {
                        breakpoint: '768px',
                        numVisible: 1,
                        numScroll: 1,
                    },
                ]}
            />
            <style jsx>{`
                .media-item {
                    text-align: center;
                }
                .media-image {
                    max-width: 100%;
                    height: auto;
                    max-height: 400px; /* Adjust the max-height as needed */
                    margin: 0 auto;
                }
                .media-name {
                    margin-top: 1rem;
                    font-size: 2rem;
                }
                    .carousel-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.p-carousel {
  display: block;
  width: 100%;
}

.p-carousel-item {
  flex: 0 0 auto;
  width: auto;
}
            `}</style>
        </motion.div>
    )
}
export default HomeMedia;
