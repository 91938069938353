import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card'
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview'
import 'primeflex/primeflex.css';
import Countdown from './Countdown';
import { DataContext } from './DataContext';

import { useTranslation } from 'react-i18next';

import { Image } from 'primereact/image';
import im from './my_images/tourn_home.png'
import { motion } from "framer-motion";

function HomeTournaments({ setActiveTab }) {
    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleCards, setIsVisibleCards] = useState(false);

    const strategicRef = useRef(null);
    const cardsRef = useRef(null);

    const [upcomingTournaments, setUpcomingTournaments] = useState([])
    const [pastTournaments, setPastTournaments] = useState([])
    const [isMobile, setIsMobile] = useState(false);
    const { pastTournamentsArr, upcomingTournamentsArr } = useContext(DataContext)
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        let upcomingTmp = [upcomingTournamentsArr[0]]
        setUpcomingTournaments(upcomingTmp);
        let pastTmp = [pastTournamentsArr[0]]
        setPastTournaments(pastTmp)
        setIsMobile(window.innerWidth <= 768);
    }, [pastTournamentsArr, upcomingTournamentsArr])

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isVisible) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.2 }
        );

        if (strategicRef.current) observer.observe(strategicRef.current);
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        const observer2 = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isVisibleCards) {
                    setIsVisibleCards(true);
                }
            },
            { threshold: 0.1 }
        );
        if (cardsRef?.current) observer2.observe(cardsRef.current);
        return () => observer2.disconnect();
    }, [isMobile]);

    const pastFooter = (
        < div className="flex flex-wrap justify-content-end gap-2" >
            <Button size='small' label={t("details")} icon="pi pi-info-circle" onClick={() => window.open(pastTournaments[0].details, '_blank')} />
        </div >
    )

    const upcomingFooter = (
        < div className="flex flex-wrap justify-content-end gap-2" >
            <Button size='small' label={t("details")} icon="pi pi-info-circle" onClick={() => window.open(upcomingTournaments[0].details, '_blank')} />
        </div >
    )

    const tournamentsFooter = (
        < div className="flex flex-wrap justify-content-center gap-2" >
            <Button size='large' label={t("terminarz")} icon="pi pi-calendar" className="p-button-secondary" onClick={() => {
                navigate('/schedule');
                setActiveTab("Schedule");
            }} />
        </div >
    )

    const tournamentUpcomingTemplate = (tournament) => {
        return (
            <div className="col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                    <img className="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" src={tournament.image} alt={tournament.name} />
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                            <div className="text-2xl font-bold text-900">{tournament.name}</div>
                            <div className="flex align-items-center gap-3">
                                <span className="flex align-items-center gap-2">
                                    <i className="pi pi-calendar"></i>
                                    <span className="font-semibold">{tournament.date}</span>
                                </span>
                            </div>
                            <Button link icon="pi pi-link" label={t("livescoring")} onClick={() => window.open(tournament.livescoring, '_blank')} />
                        </div>
                    </div>
                </div>
                <Countdown date={tournament.start} style={{ margin: "5px" }} />
            </div>
        )
    }

    const tournamentPastTemplate = (tournament) => {
        return (
            <div className="col-12">
                <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                    <img className="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" src={tournament.image} alt={tournament.name} />
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                            <div className="text-2xl font-bold text-900">{tournament.name}</div>
                            <div className="flex align-items-center gap-3">
                                <span className="flex align-items-center gap-2">
                                    <i className="pi pi-calendar"></i>
                                    <span className="font-semibold">{tournament.date}</span>
                                </span>
                            </div>
                            <div className="flex align-items-center gap-3">
                                <span className="flex align-items-center gap-2">
                                    <i className="pi pi-align-left"></i>
                                    <span className="font-semibold">{tournament.place}</span>
                                </span>
                            </div>
                            <Button link icon="pi pi-link" label={t("results")} onClick={() => window.open(tournament.livescoring, '_blank')} />

                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div>
            {
                isMobile ? (

                    <div className='flex flex-col gap-5 align-items-top' >
                        <motion.div className='flex gap-10 flex-col mt-5 align-items-center'
                            ref={strategicRef}
                            initial={{ opacity: 0, x: -100 }}
                            animate={isVisible ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.9, delay: 0.2 }}>
                            <div className='title-text'>{t("tournaments")}</div>
                            {tournamentsFooter}
                            <Image src={im} className='mt-10' />
                        </motion.div>
                        <motion.div initial={{ opacity: 0, x: 100 }}
                            animate={isVisibleCards ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.9, delay: 0.2 }}>

                            <Card ref={cardsRef} title={t("upcoming")} footer={upcomingFooter} style={{ width: "100%", }}>
                                <DataView value={upcomingTournaments} itemTemplate={tournamentUpcomingTemplate} />
                            </Card>
                            <Divider />
                            <Card title={t("recent")} footer={pastFooter} style={{ width: "100%", }}>
                                <DataView value={pastTournaments} itemTemplate={tournamentPastTemplate} />
                            </Card>

                        </motion.div>
                    </div>
                ) : (
                    <div className='flex gap-5 align-items-top' ref={strategicRef}>
                        <motion.div className='flex gap-10 flex-col mt-5 align-items-center'
                            initial={{ opacity: 0, x: -100 }}
                            animate={isVisible ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.9, delay: 0.2 }}>
                            <div className='title-text'>{t("tournaments")}</div>
                            {tournamentsFooter}
                            <Image src={im} className='mt-10' />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 0 }}
                            animate={isVisible ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 1.5, delay: 1 }}>
                            <div className=" flex justify-content-center">
                                <Card title={t("upcoming")} footer={upcomingFooter} style={{ width: "50%", }}>
                                    <DataView value={upcomingTournaments} itemTemplate={tournamentUpcomingTemplate} />
                                </Card>
                                <Divider layout="vertical" />
                                <Card title={t("recent")} footer={pastFooter} style={{ width: "50%", }}>
                                    <DataView value={pastTournaments} itemTemplate={tournamentPastTemplate} />
                                </Card>
                            </div>

                        </motion.div>
                    </div>

                )
            }
        </div >

    );
}

export default HomeTournaments;
