import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';
import aboutHomePic from './my_images/about_home.jpg';
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from 'react';
import img2 from './my_images/imgright.jpg';


function AboutHome2({ setActiveTab, isMobile }) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isVisible, setIsVisible] = useState(false);
    const cardRef = useRef(null);

    const footer = (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={isVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.4 }}
            className='m-2'
        >
            <Button label={t("more me")} icon="pi pi-user" className='m-2 p-button-secondary' onClick={() => {
                navigate('/about');
                setActiveTab("About me");
            }} />
        </motion.div>
    );


    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isVisible) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.3 }
        );
        if (cardRef.current) observer.observe(cardRef.current);
        return () => observer.disconnect();
    }, []);

    return (
        isMobile ?
            <div
                className="card relative flex flex-col justify-end mt-5 p-3"
                style={{
                    backgroundImage: `url(${img2})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "5px",
                    height: "45rem",
                }}
            >
                {/* Name in the top-right corner */}
                <div
                    className="absolute top-1 right-2 m-1"
                    style={{
                        fontWeight: "700", fontSize: "3rem", lineHeight: "3rem",
                        textAlign: "right",
                    }}
                >
                    Michał <br /> Bargenda
                </div>

                {/* Text in the bottom-left corner */}
                <motion.p
                    className="m-2 bg-gray-500/80"
                    ref={cardRef}
                    initial={{ opacity: 0, y: 20 }}
                    animate={isVisible ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    style={{
                        borderRadius: "5px",
                        padding: "5px",
                        maxWidth: "100%",
                    }}
                >
                    {t("homebio")}
                </motion.p>

                {footer}
            </div>
            :
            <div
                className="card relative flex flex-col justify-end mt-5 p-5"
                style={{
                    backgroundImage: `url(${aboutHomePic})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "5px",
                    height: "45rem",
                }}
            >
                {/* Name in the top-right corner */}
                <div
                    className="absolute top-5 right-5 m-3"
                    style={{ fontWeight: "700", fontSize: "6rem" }}
                >
                    Michał Bargenda
                </div>
                {/* Text in the bottom-left corner */}
                <motion.p
                    className="m-2"
                    ref={cardRef}
                    initial={{ opacity: 0, y: 20 }}
                    animate={isVisible ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    style={{
                        borderRadius: "5px",
                        padding: "5px",
                        maxWidth: "60%",
                    }}
                >
                    {t("homebio")}
                </motion.p>

                {footer}
            </div>

    );
}

export default AboutHome2;
