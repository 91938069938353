import { useState, useEffect, useRef } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Dropdown } from 'primereact/dropdown';
import logo from "../logo.png"
import React from 'react';
import './css/Navbar.css'



function Navbar({ activeTab }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const menu = useRef(null);
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const [lang, setLang] = useState(i18n.language);
    const LANGUAGES = [
        { label: "Polski", code: "pl", flag: "pl" },
        { label: "English", code: "en", flag: "gb" }
    ];
    const onChangeLang = (e) => {
        const lang_code = e.target.value.code;
        i18n.changeLanguage(lang_code);
        setLang(e.target.value)
    };



    const items = [
        {
            label: `${t("home")}`/*  <img width={30} src={logo}></img> */,
            icon: 'pi pi-home',
            to: '/',
            function: "page"

        },
        {
            label: `${t("about me")}`,
            icon: 'pi pi-user',
            to: '/about',
            function: "page"
        },
        {
            label: `${t("partners")}`,
            icon: 'pi pi-fw pi-briefcase',
            to: '/partners',
            function: "page"
        },
        {
            label: `${t("team")}`,
            icon: 'pi pi-bolt',
            to: '/coaching-staff',
            function: "page"
        },
        {
            label: `${t("schedule")}`,
            icon: 'pi pi-calendar',
            to: '/schedule',
            function: "page"
        },
        {
            label: `Media`,
            icon: 'pi pi-camera',
            to: '/media',
            function: "page"
        },
        {
            label: `${t("contact me")}`,
            icon: 'pi pi-envelope',
            to: 'mailto:michal.bargenda@pgapolska.com',
            function: "page"
        },

        {
            icon: 'pi pi-instagram',
            to: 'https://www.instagram.com/michalbargenda/',
            function: "social"
        },
        {
            icon: 'pi pi-facebook',
            to: 'https://www.facebook.com/MichalBargendaGolfPro',
            function: "social"
        }

    ];
    const itemsPhone = [
        {
            icon: 'pi pi-instagram',
            to: 'https://www.instagram.com/michalbargenda/',
            function: "social"
        },
        {
            icon: 'pi pi-facebook',
            to: 'https://www.facebook.com/MichalBargendaGolfPro',
            function: "social"
        },
    ];

    const itemsMenuPhone = [
        {
            label: `${t("home")}`,
            icon: 'pi pi-home',
            to: '/',
            function: "page",
            command: (event) => handleMobileTabChange(event)

        },
        {
            label: `${t("about me")}`,
            icon: 'pi pi-user',
            to: '/about',
            function: "page",
            command: (event) => handleMobileTabChange(event)
        },
        {
            label: `${t("partners")}`,
            icon: 'pi pi-fw pi-briefcase',
            to: '/partners',
            function: "page",
            command: (event) => handleMobileTabChange(event)
        },
        {
            label: `${t("team")}`,
            icon: 'pi pi-bolt',
            to: '/coaching-staff',
            function: "page",
            command: (event) => handleMobileTabChange(event)
        },
        {
            label: `${t("schedule")}`,
            icon: 'pi pi-calendar',
            to: '/schedule',
            function: "page",
            command: (event) => handleMobileTabChange(event)
        },
        {
            label: `Media`,
            icon: 'pi pi-camera',
            to: '/media',
            function: "page",
            command: (event) => handleMobileTabChange(event)
        },
        {
            label: `${t("contact me")}`,
            icon: 'pi pi-envelope',
            to: '#',
            function: "page",
            command: (event) => handleMobileTabChange(event)
        }
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    function handleMobileTabChange(e) {
        if (e.item.label === "Contact" || e.item.label === "Kontakt") {
            window.location.href = "mailto:michal.bargenda@pgapolska.com";

        }
        else if (e.item.function === "social") {
            window.open(e.value.to, '_blank');
        } else {
            const newPath = `${e.item.to}`;
            navigate(newPath);
            setActiveIndex(e.index);
        }
    }

    useEffect(() => {

        let page = window.location.href.split("/")[3]
        switch (page) {
            case "about":
                setActiveIndex(1);
                break;
            case "partners":
                setActiveIndex(2);
                break;
            case "coaching-staff":
                setActiveIndex(3);
                break;
            case "schedule":
                setActiveIndex(4);
                break;
            case "media":
                setActiveIndex(5);
                break;
            default:
                setActiveIndex(0);
                break;
        }
    }, [window.location.href])

    function handleTabChange(e) {
        if (e.value.label === "Contact" || e.value.label === "Kontakt") {
            window.open('mailto:michal.bargenda@pgapolska.com', '_blank');
        }
        else if (e.value.function === "social") {
            window.open(e.value.to, '_blank');
        } else {
            const newPath = `${e.value.to}`;
            navigate(newPath);
            setActiveIndex(e.index);
        }
    }
    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center" style={{ height: "100%" }}>
                    <img alt={option.label} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                        className={`mr-3 flag flag-${option.flag}`} style={{ width: '18px' }} />
                </div>
            );
        }
        return (<div className="flex align-items-center" style={{ height: "100%" }}>
            <img alt={props.placeholder} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                className={`mr-3 flag flag-${props.placeholder === "en" ? 'gb' : 'pl'}`} style={{ width: '18px' }} />
        </div>);
    };
    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.label} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.flag}`} />
                <div>{option.label}</div>
            </div>
        );
    };
    return (
        <div>
            {isMobile ? (
                // Render hamburger menu for mobile
                <div className='flex p-tabmenu'>
                    <TieredMenu model={itemsMenuPhone} popup ref={menu} breakpoint="767px" onChange={(e) => console.log(e)} />
                    <Button className='p-button-outlined p-button-secondary' icon="pi pi-bars" onClick={(e) => menu.current.toggle(e)} style={{ margin: "5px", marginRight: "10px", minWidth: "60px" }} />
                    <TabMenu
                        model={itemsPhone}
                        activeIndex={-1}
                        onTabChange={(e) => handleTabChange(e)}
                    />
                    <Dropdown value={lang} onChange={(e) => onChangeLang(e)} options={LANGUAGES} optionLabel="name" placeholder={lang}
                        valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="m-1" />
                </div>
            ) : (
                // Render TabMenu for desktop
                <div className='flex p-tabmenu'>
                    <TabMenu
                        model={items}
                        activeIndex={activeIndex}
                        onTabChange={(e) => handleTabChange(e)}
                        style={{ fontSize: "0.8rem" }}
                    />
                    <Dropdown value={lang} onChange={(e) => onChangeLang(e)} options={LANGUAGES} optionLabel="name" placeholder={lang}
                        valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="m-1" />

                </div>
            )}
        </div>
    );
}

export default Navbar;
