import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DataContext } from './DataContext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { DataView } from 'primereact/dataview';
import { motion } from "framer-motion";

function SponsorsHome({ setActiveTab, isMobile }) {

    const [isVisible, setIsVisible] = useState(false);

    const strategicRef = useRef(null);

    const navigate = useNavigate();
    const [sponsors, setSponsors] = useState([]);
    const [strategicSponsor, setStrategicSponsor] = useState([])
    const { t } = useTranslation();
    const { sponsorsArr, strategicPartner } = useContext(DataContext)

    useEffect(() => {
        setSponsors(sponsorsArr);
        setStrategicSponsor(strategicPartner)
    }, [sponsorsArr, strategicPartner]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isVisible) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.4 }
        );
        if (strategicRef.current) observer.observe(strategicRef.current);
        return () => observer.disconnect();
    }, []);

    const strategicPartnerTemplate = (sponsor) => {
        return (
            <motion.div className="sponsor-item  flex align-items-center justify-content-center flex-column"
                style={{ width: "75%" }}
                initial={{ opacity: 0, y: -30 }}
                animate={isVisible ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, delay: 0.3 }}
            >
                <img
                    src={sponsor.image}
                    alt={sponsor.name}
                    className="sponsor-image "
                    onClick={() => window.open(sponsor.link, '_blank')}
                />
                <Button className="text-2xl text-primary-800" link onClick={() => window.open(sponsor.link, '_blank')} label={sponsor.name} />
            </motion.div>
        );
    }

    const footer = (
        < div className="flex flex-wrap justify-content-end gap-2" >
            <Button label={t("more")} icon="pi pi-fw pi-briefcase" className="p-button-outlined p-button-secondary" onClick={() => {
                navigate('/partners');
                setActiveTab("Partners");
            }} />
        </div >
    )
    const partnerItem = (sponsor) => {
        return (
            <div className="col-6 sm:col-6 lg:col-4 xl:col-4 p-1 flex align-items-center justify-content-center "  >
                <div className="flex flex-column align-items-center gap-2 py-5">
                    <img className="sponsor-img w-8 sm:w-5rem xl:w-9rem shadow-2 block xl:block mx-auto border-round" src={sponsor.image} alt={sponsor.name} onClick={() => window.open(sponsor.link, '_blank')} />
                    <Button className="text-1xl font-bold text-primary-700" link label={sponsor.name} onClick={() => window.open(sponsor.link, '_blank')} />
                </div>
            </div>
        );
    };
    return (
        <div footer={footer} ref={strategicRef} className='flex flex-col align-items-center mb-10'>
            <motion.div className='title-text'
                initial={{ opacity: 0, x: 200 }}
                animate={isVisible ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.1 }}
            >{t("partners")}</motion.div>
            <motion.div initial={{ opacity: 0, x: 200 }}
                animate={isVisible ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.1 }}
                style={{ fontWeight: "200", fontSize: "1.7rem", color: "var(--primary-700)", textAlign: "center", fontFamily: "'Caveat', cursive" }}
                className='mb-4 '>
                {t("partner desc")}
            </motion.div>
            <div className='flex align-items-center justify-content-center flex-column'>
                {strategicPartnerTemplate(strategicSponsor)}
            </div>
            <div >
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={isVisible ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.7, delay: 1.2 }}>
                    <DataView className='sponsor-container' value={sponsors.filter((s) => s.type === "Partner")}
                        itemTemplate={partnerItem} layout={'grid'} style={{ width: '100%' }}
                        pt={{
                            content: {
                                style: {
                                    backgroundColor: "transparent"
                                }
                            }
                        }}
                    />
                </motion.div>
            </div>
        </div >
    );
}

export default SponsorsHome;
