import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'primereact/card'
import { Timeline } from 'primereact/timeline';
import { Image } from 'primereact/image'
import juniorImage from './my_images/JuniorImage.jpg';
import kidImage from './my_images/KidImage.jpg'
import collegeImage from './my_images/collegeImg.jpg';
import gradImage from './my_images/grad.JPG';
import nationalImage from './my_images/natonal.jpeg';
import sobienieImage from './my_images/sobienie.jpeg';
import trophyImage from './my_images/trophies.jpg';
import proImage from './my_images/turnPro.JPG';
import seniorImage from './my_images/senior.jpg';
import rookie from './my_images/rookie.jpg'
import about1 from './my_images/about1.png';
import about2 from './my_images/about2.png';
import { motion } from "framer-motion";

import './css/About.css'

import { useTranslation } from "react-i18next";
import Bag from './Bag';
function About({ setActiveTab }) {

    const { t } = useTranslation();
    const events = [
        {
            status: `${t("Started playing golf")}`,
            date: '2009',
            color: "green",
            image: kidImage,
            width: 300,
            key: 1,
            content: `${t("Started playing golf text")}`,

        },
        {
            status: `${t("Competetive junior career")}`,
            date: '2012',
            color: 'orange',
            image: juniorImage,
            width: 400,
            key: 2,
            content: `${t("Competetive junior career text")}`
        },
        {
            status: `${t("Polish Golf Team")}`,
            date: '2013', color: 'red', image: nationalImage, key: 3,
            width: 300,
            content: `${t("Polish Golf Team text")}`
        },
        {
            status: 'Sobienie Królewskie G&CC', date: '2015', color: 'gold',
            image: sobienieImage, width: 400, key: 4,
            content: `${t("Sobienie")}`
        },
        {
            status: `${t("Junior Accomplishments")}`, date: '', color: '#673AB7', image: trophyImage,
            width: 200, key: 5,
            content: `${t("Junior Accomplishments text")}`
        },
        {
            status: `${t("Collegiate Golf")}`, date: '2019', color: 'blue', image: collegeImage,
            width: 400,
            key: 6,
            content: `${t("Collegiate Golf text")}`
        },
        {
            status: `${t("Senior Accomplishments")}`, date: '', color: '#673AB7',
            image: seniorImage, width: 400, key: 7,
            content: `${t("Senior Accomplishments text")}`
        },
        {
            status: `${t("Graduation")}`, date: '27/05/2023', color: 'yellow',
            image: gradImage, width: 350, key: 8,
            content: `${t("Graduation text")}`
        },

        {
            status: `${t("Professional Golf")}`, date: '16/10/2023', color: '#607D8B',
            image: proImage, width: 400, key: 9,
            content: `${t("Professional Golf text")}`
        },
        {
            status: `${t("Rookie Season")}`, date: '2024', color: 'rgb(0, 217, 255)', image: rookie, width: 300, key: 10,
            content: `${t("Rookie Season text")}`
        }
    ];




    const [isMobile, setIsMobile] = useState(null);
    useEffect(() => {
        // Initial check for mobile device on component mount
        setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        setActiveTab("About")
    }, []);

    const [isVisibleIntro, setIsVisibleIntro] = useState(false);
    const introRef = useRef(null);
    const introRefMobile = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isVisibleIntro) {
                    setIsVisibleIntro(true);
                }
            },
            { threshold: 0.3 }
        );

        if (introRef.current) observer.observe(introRef.current);
        return () => observer.disconnect();
    }, [isMobile]);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isVisibleIntro) {
                    setIsVisibleIntro(true);
                }
            },
            { threshold: 0.2 }
        );

        if (introRefMobile.current) observer.observe(introRefMobile.current);
        return () => observer.disconnect();
    }, [isMobile]);

    const [isVisibleJourney, setIsVisibleJourney] = useState(false);
    const journeyRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isVisibleJourney) {
                    setIsVisibleJourney(true);
                }
            },
            { threshold: 0.2 }
        );

        if (journeyRef.current) observer.observe(journeyRef.current);
        return () => observer.disconnect();
    }, [isMobile]);

    const [isVisibleBag, setIsVisibleBag] = useState(false);
    const bagRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isVisibleBag) {
                    setIsVisibleBag(true);
                }
            },
            { threshold: 0.2 }
        );

        if (bagRef.current) observer.observe(bagRef.current);
        return () => observer.disconnect();
    }, [isMobile]);

    const customizedMarker = (item) => {
        return (
            <div
                style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 1 // Ensures it stays on top
                }}>
                <span style={{
                    backgroundColor: item.color
                }} className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" >
                </span>
            </div>

        );
    };

    const customizedContent = (item) => {

        return (
            <Card title={item.status} subTitle={item.date}
                style={{ width: "35rem" }}
                pt={{
                    body: {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }
                    },
                    root: {
                        style: {
                            height: "34rem"
                        }
                    }
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    {item.image && <img src={item.image} alt={item.name} width={item.width}
                        style={{ boxShadow: "0 0 10px var(--primary-900)", borderRadius: "5px" }} />}
                    <p className='m-2 text-justify'>{item.content}</p>
                </div>
            </Card >
        );
    };

    const customizedContentMobile = (item) => {

        return (
            <Card title={item.status} subTitle={item.date}
                style={{ width: "15rem" }}
                pt={{
                    body: {
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }
                    },
                    root: {
                        style: {
                            height: "44rem"
                        }
                    }
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    {item.image && <img src={item.image} alt={item.name} width={item.width * 0.6}
                        style={{ boxShadow: "0 0 10px var(--primary-900)", borderRadius: "5px" }} />}
                    <p lang="pl" className='m-2 break-word' style={{
                        textAlign: "justify",
                        fontSize: "0.9rem"
                    }}>{item.content}</p>
                </div>
            </Card >
        );
    };


    return (
        <div>
            {isMobile == null ?
                <div></div> :
                <div>
                    {isMobile ? (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                            <h1 className='text-primary-900 text-weight-400 text-center text-7xl m-10' style={{ fontFamily: "'Caveat', cursive" }}>MICHAŁ BARGENDA</h1>
                            <motion.div ref={introRef} initial={{ opacity: 0, x: -100 }}
                                animate={isVisibleIntro ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 1.5, delay: 0.4 }} className='flex flex-col align-items-center'>
                                <Image className='m-2' src={about1} style={{ width: "30%" }} />
                                <div className='m-2 text-justify text-primary-800 text-xl' style={{ fontFamily: "'Montserrat', sans-serif", width: "80%" }}>
                                    {t("about intro")}</div>
                                <Image className='m-2' src={about2} style={{ width: "25%" }} />
                            </motion.div>
                            <motion.div ref={journeyRef}
                                initial={{ opacity: 0, x: 200 }}
                                animate={isVisibleJourney ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 2, delay: 0.4 }}
                                className='mr-2 ml-2' style={{
                                    width: "97%", display: 'flex', flexDirection: 'column', alignItems: 'center',
                                    border: "20px var(--primary-400) solid", padding: "10px",
                                }}>
                                <div className='title-text mb-2' style={{ fontFamily: "'Caveat', cursive" }}>{t("journey")}</div>
                                <div style={{ width: "100%", overflowX: "scroll", scrollbarWidth: "none" }}>
                                    <div style={{ width: "490%" }}>
                                        {<Timeline
                                            layout='horizontal'
                                            value={events}
                                            align="bottom"
                                            marker={customizedMarker}
                                            content={customizedContentMobile}
                                            pt={{
                                                event: {
                                                    style: {
                                                        maxWidth: "40rem",
                                                        minHeight: "50rem"
                                                    }
                                                },
                                                content: {
                                                    style: {
                                                        minHeight: "46rem"
                                                    }
                                                },
                                                separator: {
                                                    style: {
                                                        position: "relavite",
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }
                                                },
                                                marker: {
                                                    style: {
                                                        position: "absolute",
                                                        left: "50%",
                                                        transform: "translateX(-50%)"
                                                    }
                                                },
                                                connector: {
                                                    style: {
                                                        position: "absolute",
                                                        left: "15rem",
                                                        transform: "translateX(-50%)",
                                                    }
                                                }
                                            }}
                                        />}

                                    </div>
                                </div>
                            </motion.div>
                        </div >
                    )
                        :
                        (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                <h1 className='text-primary-900 text-weight-400  text-8xl m-10 ' style={{ fontFamily: "'Caveat', cursive" }}>MICHAŁ BARGENDA</h1>
                                <motion.div ref={introRef} initial={{ opacity: 0, y: -100 }}
                                    animate={isVisibleIntro ? { opacity: 1, y: 0 } : {}}
                                    transition={{ duration: 1.5, delay: 0.4 }} className='flex align-items-center'>
                                    <Image className='m-2' src={about1} style={{ width: "20%" }} />
                                    <div className='m-8 text-justify text-primary-800 text-xl' style={{ fontFamily: "'Montserrat', sans-serif", width: "80%" }}>
                                        {t("about intro")}</div>
                                    <Image className='m-2' src={about2} style={{ width: "15%" }} />
                                </motion.div>
                                <motion.div
                                    ref={journeyRef}
                                    initial={{ opacity: 0, x: 200 }}
                                    animate={isVisibleJourney ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 2, delay: 0.4 }}
                                    className='mr-2 ml-2' style={{
                                        width: "97%", display: 'flex', flexDirection: 'column', alignItems: 'center',
                                        border: "20px var(--primary-400) solid", padding: "10px",
                                    }}>
                                    <div className='title-text mb-2' style={{ fontFamily: "'Caveat', cursive" }}>{t("journey")}</div>
                                    <div style={{ width: "100%", overflowX: "scroll", scrollbarWidth: "none" }}>
                                        <div style={{ width: "490%" }}>
                                            {<Timeline
                                                layout='horizontal'
                                                value={events}
                                                align="bottom"
                                                marker={customizedMarker}
                                                content={customizedContent}
                                                pt={{
                                                    event: {
                                                        style: {
                                                            maxWidth: "40rem",
                                                            minHeight: "40rem"
                                                        }
                                                    },
                                                    content: {
                                                        style: {
                                                            minHeight: "36rem"
                                                        }
                                                    },
                                                    separator: {
                                                        style: {
                                                            position: "relavite",
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }
                                                    },
                                                    marker: {
                                                        style: {
                                                            position: "absolute",
                                                            left: "50%",
                                                            transform: "translateX(-50%)"
                                                        }
                                                    },
                                                    connector: {
                                                        style: {
                                                            position: "absolute",
                                                            left: "35rem",
                                                            transform: "translateX(-50%)",
                                                        }
                                                    }
                                                }}
                                            />}

                                        </div>
                                    </div>
                                </motion.div>

                            </div >)
                    }
                    <motion.div ref={bagRef}
                        initial={{ opacity: 0, y: -200 }}
                        animate={isVisibleBag ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 2, delay: 0.4 }}
                        className='mb-2 mt-6' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div className='title-text' style={{ fontFamily: "'Caveat', cursive" }}>{t("witb")}</div>
                        <Bag isMobile={isMobile} />
                    </motion.div>
                </div >}
        </div>
    );
}

export default About;
