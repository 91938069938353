import { DataView } from "primereact/dataview";
import { Image } from "primereact/image"
import ping from './sponsor_images/ping.webp'
import pingV from './sponsor_images/ping_vert.jpg'
import driver from './clubs_images/driver.PNG'
import w3 from './clubs_images/3w.PNG'
import w7 from './clubs_images/7w.PNG'
import h3 from './clubs_images/3h.PNG'
import irons from './clubs_images/iron.PNG'
import wedge from './clubs_images/wedge.png'
import putter from './clubs_images/putter.PNG'
import { useTranslation } from 'react-i18next';


function Bag({ isMobile }) {
    const { t } = useTranslation();

    const bag = [
        {
            kind: "Driver",
            name: "PING G440 MAX",
            description: `${t("driver desc")}`,
            picture: driver
        },
        {
            kind: "3 wood",
            name: "PING G440 MAX",
            description: `${t("3w desc")}`,
            picture: w3
        },
        {
            kind: "7 wood",
            name: "PING G440 MAX",
            description: `${t("7w desc")}`,
            picture: w7
        },
        {
            kind: "3 hybrid",
            name: "PING G440 MAX",
            description: `${t("3h desc")}`,
            picture: h3
        },
        {
            kind: `${t("irons")}`,
            name: "PING i230 4-W",
            description: `${t("irons desc")}`,
            picture: irons
        },
        {
            kind: `${t("wedges")}`,
            name: "PING s159",
            description: `${t("wedges desc")}`,
            picture: wedge
        },
        {
            kind: "Putter",
            name: "PING PLD DS72",
            description: `${t("putter desc")}`,
            picture: putter
        },
        {
            kind: "Ball",
            name: "Titleist ProV1",
            description: ""
        },

    ]

    const item = (c) => {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="col-12 sm:col-12 lg:col-4 xl:col-4 p-1 flex-col align-items-center  text-primary-800 gap-1"  >
                <div className="font-bold text-4xl">{c.kind}:</div>
                <Image src={c.picture ?? ""} width={100} />
                <div className="font-bold text-2xl">{c.name}</div>
                <div style={{ textAlign: "center" }}>{c.description}</div>

            </div>
        );
    };
    return (
        <div style={{
            width: "95%",
            backgroundImage: `url(${isMobile ? "" : ping})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "var(--primary-100)", // Adjust transparency here
            backgroundBlendMode: "overlay", // Try 'multiply', 'overlay', etc.
            borderRadius: "1px",
            border: "15px var(--primary-700) solid",
        }}>
            <DataView value={bag}
                itemTemplate={item} layout={'grid'} style={{
                    width: '100%'
                }}
                pt={{
                    content: {
                        style: {
                            backgroundColor: "transparent"
                        }
                    }
                }}
            />
        </div>


    )
}
export default Bag;