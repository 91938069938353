import React, { useEffect, useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import ProgressiveImage from 'react-progressive-graceful-image'
import { DataContext } from './DataContext';
import './css/Home.css'
import HomeTournaments from './HomeTournaments';
import HomeMedia from './HomeMedia';
import AboutHome2 from './AboutHome2';
import SponsorsHome from './SponsorsHome';


function Home2({ setActiveTab }) {
    const { t } = useTranslation();

    const [isMobile, setIsMobile] = useState(false);

    const [strategic, setStrategic] = useState(null)
    const { strategicPartner } = useContext(DataContext)
    useEffect(() => {
        setStrategic(strategicPartner)
    }, [strategicPartner]);
    useEffect(() => {

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed

        };

        window.addEventListener('resize', handleResize);

        // Initial check for mobile device on component mount
        setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        setActiveTab("Home");
    }, [])


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflowX: 'hidden' }}>

            <div style={{ width: "90%" }}>
                <AboutHome2 setActiveTab={setActiveTab} isMobile={isMobile} />
            </div>

            <div className='other-home mt-4' style={{ width: '90%', margin: '20px' }}>
                <SponsorsHome setActiveTab={setActiveTab} isMobile={isMobile} />
            </div>
            <div className='other-home'
                style={{
                    width: "95%", margin: "20px"
                }}>
                <HomeTournaments setActiveTab={setActiveTab} />
            </div>
            <div className='other-home'
                style={{
                    width: "95%", margin: "20px"
                }}>
                <HomeMedia isMobile={isMobile} setActiveTab={setActiveTab} />
            </div>
        </div>
    );
}

export default Home2;
